<template>
  <div>
    <select-warehouse-model
      v-model="filters.id_warehouse"
      :label="$t('labels.warehouse')"
      dense
      outlined
      clearable
      hide-details
      @change="onUpdate"
    ></select-warehouse-model>
  </div>
</template>

<script>
export default {
  name: "PickupStep1",
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filters: {},
  }),
  created() {
    this.filters = { ...this.selected };
  },
  methods: {
    onUpdate() {
      this.$emit("onUpdate", this.filters);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
